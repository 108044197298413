<template>
    <div id="addSubscribe">
        <CSDialog
            :dialog-title="!subscribeId ? '添加关注人' : '修改关注人'"
            :dialog-visible="subscribePersonVisible"
            @onClose="subscribePersonVisible = false"
            @onConfirm="addSubscribePerson()"
            dialog-width="906px"
        >
            <template v-slot:dialog-content>
                <div class="preview-tenant" style="font-size:24px;padding:30px">
                    <div class="field" style="margin-bottom:30px">
                        <span class="field-label">人脸照片</span>
                        <div v-if="!newChangeSubPerson.addPersonPhoto" @click="uploadAddFace()" class="upload-photo">
                            <img
                                src="@/assets/upload.png"
                                alt=""
                                style="margin:10px auto"
                            />
                            <p style="color:#999">上传图片</p>
                        </div>
                        <div v-else="newChangeSubPerson.addPersonPhoto" class="photo-view">
                            <img :src="snapImgHost + newChangeSubPerson.addPersonPhoto" alt=""/>
                            <img v-if="!subscribeId"
                                @click="deleteFacePhoto()"
                                src="../../assets/icon4.png"
                                style="
                                        width:15px;
                                        height:15px;
                                        position:absolute;
                                        top:-5px;
                                        right:-5px;
                                    "
                            />
                        </div>
                        <input type="file" accept="image/*" @change="showImage($event,'addSub')" hidden
                               id="addFaceBtn"/>
                    </div>
                    <div class="field">
                        <span class="field-label">关注原因</span>
                        <span class="field-content">
                            <label @click="changeAttentionUserType(1)" style="margin-right:20px;">
                                <div :class="newChangeSubPerson.isVIP === 1 ? 'class-b' : 'class-a'"></div>
                                <span>VIP</span>
                            </label>
                            <label @click="changeAttentionUserType(2)">
                                <div :class="newChangeSubPerson.isVIP === 2 ? 'class-b' : 'class-a'"></div>
                                <span style="position:relative;bottom:1px;">可疑人</span>
                            </label>
                        </span>
                    </div>
                    <div class="field">
                        <span class="field-label">备注</span>
                        <textarea
                            style="
                                width:690px;
                                height:200px;
                                padding:15px;
                                resize:none;
                                border:0px;
                                border-radius:10px;
                                background-color:#F0F0F0
                            "
                            maxlength="200"
                            placeholder="限200字，非必填"
                            v-model="newChangeSubPerson.addPersonNote"
                        ></textarea>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import {
    saveAttentionUserUrl,
    commonImgOssDomain,
    editFaceDataUrl,
    uploadSnapshotImageUrl,
    snapImgHost
} from "@/requestUrl";

export default {
    components: {
        CSDialog,
    },
    data() {
        return {
            snapImgHost,
            // 图片链接地址: http://cosmoss-img.oss-cn-beijing.aliyuncs.com/2021-06-29/6.jpg
            newChangeSubPerson: {
                addPersonPhoto: "",
                isVIP: "",
                addPersonNote: "",
            },
            subscribeId: null,
            commonImgOssDomain,
            subscribePersonVisible: false,
        }
    },
    created() {
        this.$vc.on(this.$route.path, "faceCapture", "addSubscribe", () => {
            this.subscribePersonVisible = true;
            this.newChangeSubPerson = {
                addPersonPhoto: "",
                isVIP: "",
                addPersonNote: "",
            }
        })
        this.$vc.on(this.$route.path, "faceCapture", "changeSubscribe", data => {
            this.subscribePersonVisible = true;
            this.subscribeId = data.id;
            this.newChangeSubPerson.addPersonPhoto = data.faceUrl.replace('https://cosmoss-snapshot.oss-cn-shanghai.aliyuncs.com/', '');
            this.newChangeSubPerson.isVIP = data.type;
            this.newChangeSubPerson.addPersonNote = data.comment;

        })
    },
    methods: {
        uploadSnapImg(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('userId', this.$vc.getCurrentStaffInfo().id);
            return this.$fly.post(uploadSnapshotImageUrl, formData).then(res => res.data || '');
        },
        /**
         * 修改关注人身份
         * @param {Number} type 1:vip 2:嫌疑人
         * */
        changeAttentionUserType(type) {
            this.newChangeSubPerson.isVIP = type;
        },
        addSubscribePerson() {
            if (this.judgement()) {
                let url = saveAttentionUserUrl;
                const {addPersonPhoto: photoUrl, addPersonNote: remark, isVIP: type} = this.newChangeSubPerson;
                const params = {
                    type,
                };
                if (this.subscribeId) {
                    url = editFaceDataUrl;
                    params.comment = remark;
                    params.id = this.subscribeId;
                } else {
                    params.photoUrl = photoUrl;
                    params.regionCode = this.$vc.getCurrentRegion().code;
                    params.remark = remark;
                }
                this.$fly.post(url, params)
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.subscribePersonVisible = false;
                        this.$vc.emit(this.$route.path, "faceCapture", "refresh", 0)
                        this.$vc.toast('操作成功');
                    })
            }
        },
        judgement() {
            return this.$vc.validate.validate(
                {
                    newChangeSubPerson: this.newChangeSubPerson
                },
                {
                    "newChangeSubPerson.addPersonPhoto": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "请选择人脸照片",
                        }
                    ],
                    "newChangeSubPerson.isVIP": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "请选择关注原因",
                        }
                    ],
                }
            )
        },
        uploadAddFace() {
            $("#addFaceBtn").trigger("click")
        },
        async showImage(event) {
            const photoFile = event.target.files;
            if (photoFile && photoFile.length > 0) {
                const photo = photoFile[0];
                this.newChangeSubPerson.addPersonPhoto = await this.uploadSnapImg(photo);
            }
        },
        deleteFacePhoto() {
            this.newChangeSubPerson.addPersonPhoto = "";
        },
    },
}
</script>

<style lang="stylus" scoped>
.preview-tenant
    color #000

    .field
        &-label
            width 100px
            display inline-block
            margin-right 30px
            text-align right
            vertical-align top
            margin-bottom 30px

        &-content
            display inline-block
            max-width calc(100% - 240px)

.el-divider__text.is-left
    font-size 24px

.class-a
    position relative
    margin-right 5px
    top 2px
    left 0
    display inline-block
    width 20px
    height 20px
    background url('../../assets/icon5.png')

.class-b
    position relative
    margin-right 5px
    top 2px
    left 0
    display inline-block
    width 20px
    height 20px
    background url('../../assets/icon3.png')
</style>
